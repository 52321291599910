/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, VideoIframe, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-loss/what-is-audiogram-how-to-read-it/'], ['en', 'https://www.hear.com/resources/hearing-loss/what-is-audiogram-how-to-read-it/'], ['en-US', 'https://www.hear.com/resources/hearing-loss/what-is-audiogram-how-to-read-it/'], ['en-CA', 'https://ca.hear.com/resources/hearing-loss/what-is-audiogram-how-to-read-it/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "what-is-an-audiogram-and-how-to-read-it",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-an-audiogram-and-how-to-read-it",
    "aria-label": "what is an audiogram and how to read it permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Is an Audiogram and How To Read It"), "\n", React.createElement(VideoIframe, {
    title: "How to Read an Audiogram - hear.com",
    width: "500",
    height: "315",
    src: "https://www.youtube.com/embed/bWLXnGkSubI?feature=oembed"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you get a professional hearing test, your audiologist or hearing care specialist will first examine your ears to make sure everything is okay. Then they will sit you in a quiet space with special headphones. They’ll play a series of beeps at different frequencies – in one ear at a time. When you hear the beep, you raise your hand or hit a button. The beeps will get softer and be more difficult to hear until you can’t hear them anymore. After your hearing test, the results will be presented on what hearing care professionals call an audiogram."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "basics-of-an-audiogram",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#basics-of-an-audiogram",
    "aria-label": "basics of an audiogram permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Basics of an audiogram"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The audiogram shows a series of X’s and O’s marked on a graph. These letters represent your left ear (X) and your right ear (O). Their position on the graph indicates the softest sounds you can hear across a range of frequencies. This is called your hearing threshold. Running from bottom to top on the left side of the graph is loudness in decibels. Low- to high-pitch sounds (frequency in hertz) are shown from left to right at the top of the graph. In general, this is what most audiograms look like."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Pure-Tone-Audiogram.png",
    alt: "Pure Tone Audiogram Graph",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "reading-your-audiogram",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reading-your-audiogram",
    "aria-label": "reading your audiogram permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reading your audiogram"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Each symbol (X’s and O’s) on the chart represents your threshold for a given frequency. The lower the symbol on the graph, the greater your hearing loss at that frequency. This means you would need more amplification to get as close as possible to normal hearing. The degrees of hearing loss are typically classified as mild, moderate, severe, or profound – but anything below 20 decibels makes it harder to understand. In the example above, you’ll notice the symbols dip from 2000-8000 Hz, which indicates a high-frequency hearing loss that is mild-to-severe. This would indicate you have trouble hearing high-pitched sounds like birds singing, female voices and certain words. With this degree of hearing loss, you would not have the clarity you need to understand speech."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "visualizing-speech-on-an-audiogram",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#visualizing-speech-on-an-audiogram",
    "aria-label": "visualizing speech on an audiogram permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Visualizing speech on an audiogram"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The illustration below plots the sounds of human speech on an audiogram. If you look closely, you’ll see that many of the vowel sounds (A, E, I, O, U) are in the lower frequencies, while many consonant sounds are higher frequency (F, S, TH, K). When we highlight them in yellow, it looks like a banana, which is why this illustration is referred to as the “speech banana”."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/the-speech-banana.png",
    alt: "The Speech Banana Graph",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The most common type of hearing loss affects the higher frequencies, which includes consonant sounds like S, F, Th, Sh, V, K, and P.  Words like “cat” and “hat,” and “show” and “throw” can be hard for you to differentiate. If you have trouble understanding what someone is saying or ask people to repeat themselves often, this is likely why."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "an-audiogram-is-a-window-into-your-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#an-audiogram-is-a-window-into-your-hearing",
    "aria-label": "an audiogram is a window into your hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "An audiogram is a window into your hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An audiogram provides an understanding of your specific hearing loss, helps determine the appropriate treatment, and then allows a hearing care professional to customize hearing aids to your specific needs. It’s important to note that only medical-grade hearing aids can amplify the exact sounds and frequencies you can’t hear; they won’t just make everything louder. Also, the level of technology that’s best for you isn’t solely based on the severity of your hearing loss. Lifestyle factors, like whether you are socially active or spend a lot of time at home, play an important role. For instance, a conversation in the living room with the TV playing in the background may not be as challenging as a conversation in a noisy restaurant. In other words, the places you frequently find yourself in will help to determine the best solution for you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-word-about-free-online-hearing-tests",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-word-about-free-online-hearing-tests",
    "aria-label": "a word about free online hearing tests permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A word about free online hearing tests"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Perhaps you ran across a free online hearing test while browsing the web and asked yourself if it really works. Though useful in gauging how well you’re hearing, an online hearing test can only serve as an indicator of potential hearing loss. To get a proper diagnosis, you need a thorough evaluation of your hearing and a physical examination of your ear canal. A common issue that is usually discovered during the examination is an earwax blockage. This buildup of earwax could prevent you from taking a hearing test and getting accurate results. In fact, it could even be the source of your hearing loss. So if you’ve taken an online hearing test before but never visited a local hearing care professional, we advise you to speak to a specialist about getting a comprehensive evaluation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "wed-love-to-hear-from-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wed-love-to-hear-from-you",
    "aria-label": "wed love to hear from you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’d love to hear from you!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have any questions about audiograms that we didn’t address in this article, please feel free to contact us by phone at (866) 585-8861. And don’t forget to Sign up for a 30-day trial if you haven’t already – just click the button below to get started."), "\n", React.createElement(ButtonCta, {
    copy: "Sign up for a 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
